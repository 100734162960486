import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import axios from 'axios'
import { createPinia } from 'pinia'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
const pinia = createPinia()
axios.defaults.baseURL='https://sistelk.id/api/'
// axios.defaults.baseURL='http://localhost:8000/api/'
require ('@/store/subscriber')
store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{
    createApp(App).use(Quasar, quasarUserOptions).use(store)
    .use(pinia)
    .use(VueViewer)
    .use(Vue3SimpleHtml2pdf)
    .use(router).mount('#app')
})
