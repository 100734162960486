import axios from "axios";
export default{
    namespaced:true,
    state: {
        token:null,
        user:null,
        udetail:null,
  
    },
    getters:{
        authenticated(state){
            return state.token && state.user
        },
        user(state){
           return state.user
        },
        udetail(state){
           return state.udetail
        },
      },
      mutations: {
        SET_TOKEN(state, token){
          state.token=token
        },
        SET_USER(state, data){
          state.user=data
        },
        SET_UDETAIL(state, data){
          state.udetail=data
        },
    },
    actions:{
        async signIn({dispatch},credentials){
            let response=await axios.post('guruauth',credentials)
            return dispatch('attempt', response.data.token)
          },
          async attempt({commit, state}, token){
            if(token){
                commit('SET_TOKEN', token)
            }
            if(!state.token){
                return
            }
          try{
              let response= await axios.get('infoguru')
              commit('SET_USER', response.data.user)
              commit('SET_UDETAIL', response.data.udetail)
          }catch(e){
              commit('SET_TOKEN', null)
              commit('SET_USER', null)
              commit('SET_UDETAIL', null)
          }
        },
        logout({commit}){
            return axios.get('logout').then(()=>{
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                commit('SET_UDETAIL', null)
            })
          }
    }
    
}