import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: ()=>import('../views/LoginView.vue'),
    beforeEnter(to, from, next) {
      if(store.getters['auth/authenticated']){
        return next({
          name:'home'
        })
      }
      next();
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'Login'
        })
      }
      next();
    },
    meta:{
      enterClass:"animate__animated animate__bounceInUp",
      leaveClass:"animate__animated animate__fadeOutUp"
    },
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('../views/ProfilView.vue'),
    meta:{
      enterClass:"animate__animated animate__fadeInRight",
      leaveClass:"animate__animated animate__fadeOutLeft"
    },
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'Login'
        })
      }
      next();
    },
  },
  {
    path: '/dapeg',
    name: 'dapeg',
    component: () => import('../views/DapegView.vue'),
    meta:{
      enterClass:"animate__animated animate__fadeInUp",
      leaveClass:"animate__animated animate__fadeOutUp"
    },
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'Login'
        })
      }
      next();
    },
  },
  {
    path: '/ijaser',
    name: 'ijaser',
    component: () => import('../views/IjazahSertifikat.vue'),
    meta:{
      enterClass:"animate__animated animate__fadeInUp",
      leaveClass:"animate__animated animate__fadeOutUp"
    },
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'Login'
        })
      }
      next();
    },
  },
  {
    path: '/jurnal-guru',
    name: 'jugur',
    component: () => import('../views/JurnalGuru.vue'),
    meta:{
      enterClass:"animate__animated animate__fadeInUp",
      leaveClass:"animate__animated animate__fadeOutUp"
    },
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'Login'
        })
      }
      next();
    },
  },
  {
    path: '/ekstrakurikuler',
    name: 'eskul',
    component: () => import('../views/EktraKurikuler.vue'),
    meta:{
      enterClass:"animate__animated animate__fadeInUp",
      leaveClass:"animate__animated animate__fadeOutUp"
    },
    beforeEnter(to, from, next) {
      if(!store.getters['auth/authenticated']){
        return next({
          name:'Login'
        })
      }
      next();
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
