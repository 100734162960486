<template>
  <q-layout view="lHh Lpr lFf">
    <q-header v-if="authenticated">
      <q-toolbar>
        <q-btn
          v-if="authenticated"
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <img src="./assets/stelk_putih.png" style="width:130px" class="q-py-auto"/>
        <q-toolbar-title>
        </q-toolbar-title>

        <div v-if="!authenticated">Gurutta v1</div>
        <div v-if="authenticated" class="q-px-sm">
          <span class="text-uppercase">{{ user.name }}</span>
        </div>
        <div v-if="authenticated">
          <q-avatar color="red">
            <q-img v-if="!ud.file" src="./assets/user.jpg"/>
            <q-img v-else :src="kontrol.url+'storage/'+ud.file"/>
            <q-menu
              transition-show="jump-down"
              transition-hide="jump-up"
              
            >
              <q-list style="min-width: 200px">
                <q-item>
                  <q-item-section>
                  <q-item-label>{{ user.name }}</q-item-label>
                  <q-item-label caption>{{ user.role }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-separator/>
                <q-item clickable to="/profil">
                  <q-item-section>Profil</q-item-section>
                </q-item>
                <q-item clickable to="/dapeg">
                  <q-item-section>Data Kepegawaian</q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable @click="logout">
                  <q-item-section>Logout</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-avatar>
        </div>

      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="authenticated"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-white"
      :width="250"
    >
      <q-list>
        <q-item-label header>
          <img src="./assets/gurutta.png" style="height:40px"/>
        </q-item-label>
        <q-item clickable active-class="my-menu-link" to="/home">
          <q-item-section side>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Home</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable to="/jurnal-guru" active-class="my-menu-link">
          <q-item-section side>
            <q-icon name="assignment_add" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Jurnal Mengajar</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable to="/ekstrakurikuler" active-class="my-menu-link">
          <q-item-section side>
            <q-icon name="nature_people" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Ekstrakurikuler</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable to="/dapeg" active-class="my-menu-link">
          <q-item-section side>
            <q-icon name="work" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Data Kepegawaian</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable active-class="my-menu-link" to="/ijaser">
          <q-item-section side>
            <q-icon name="verified" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Sertifikat & Pelatihan</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable @click="logout">
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Logout</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
        <router-view v-slot="{ Component, route }">
        <transition
          :enter-active-class="route.meta.enterClass"
          :leave-active-class="route.meta.leaveClass"
          class="page"
        >
          <component :is="Component" />
        </transition>
      </router-view>
      <!-- <router-view/> -->
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import { mapGetters, mapActions } from 'vuex';
import { useKontrol } from './store/kontrol';


export default {
  name: 'LayoutDefault',
  components: {
    
  },

  setup () {
    const kontrol=useKontrol()
    return {
      leftDrawerOpen: ref(false),
      kontrol
    }
  },
  computed:{
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      ud:"auth/udetail"
    }),
  },
  methods:{
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.$router.replace({
          name: "Login",
        });
      });
    },
  }
}
</script>
<style>
page{ 
  position: absolute;
  top: 30px;
  --animate-duration: 0.5ms
}
.my-menu-link{
  border-left:4px solid #B71C1C
}
</style>
